import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MaterialsState } from './materials.typing'
import { getListOfMaterial } from '../../api/tags'
import findIndex from 'lodash/findIndex'

const initialState: MaterialsState = {
    best: {
        loading: false,
        list: [],
        total_count: null
    },
    certified: {
        loading: false,
        list: [],
        total_count: null
    }
}

export const fetchBestMaterial = createAsyncThunk('fetchBestMaterial', async (p: number) => {
    return await getListOfMaterial({ section: 'best', p })
})

export const fetchCertifiedMaterial = createAsyncThunk('fetchCertifiedMaterial', async (p: number) => {
    return await getListOfMaterial({ section: 'certified', p })
})

const materialsSlice = createSlice({
    name: 'materials',
    initialState,
    reducers: {
        clearBestList(state) {
            state.best.list = []
        },
        clearCertifiedList(state) {
            state.certified.list = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBestMaterial.pending, (state) => {
                state.best.loading = true
            })
            .addCase(fetchCertifiedMaterial.pending, (state) => {
                state.certified.loading = true
            })
            .addCase(fetchBestMaterial.rejected, (state) => {
                state.best.loading = false
            })
            .addCase(fetchCertifiedMaterial.rejected, (state) => {
                state.certified.loading = false
            })
            .addCase(fetchBestMaterial.fulfilled, (state, action) => {
                state.best.loading = false
                if (findIndex(state.best.list, action.payload.list[0]) === -1)
                    state.best.list = state.best.list.concat(action.payload.list)
                state.best.total_count = Number(action.payload.total_count)
            })
            .addCase(fetchCertifiedMaterial.fulfilled, (state, action) => {
                state.certified.loading = false
                if (findIndex(state.certified.list, action.payload.list[0]) === -1)
                    state.certified.list = state.certified.list.concat(action.payload.list)
                state.certified.total_count = Number(action.payload.total_count)
            })
    }
})

export const { clearBestList, clearCertifiedList } = materialsSlice.actions
export default materialsSlice.reducer
