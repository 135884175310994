import { createSlice } from '@reduxjs/toolkit'

import { CacheState } from './cacheTypings'
import findIndex from 'lodash/findIndex'
import isNull from 'lodash/isNull'
import { fetchContribution, fetchNextSlide, fetchPrevSlide } from '../contributions/contributionsSlice'

const initialState: CacheState = {
    cachedContributions: [],
    cachedNextContributions: [],
    cachedPrevContributions: []
}

const cacheSlice = createSlice({
    name: 'cache',
    initialState,
    reducers: {
        clearCache(state) {
            state.cachedContributions = []
            state.cachedNextContributions = []
            state.cachedPrevContributions = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContribution.fulfilled, (state, action: any) => {
            if (findIndex(state.cachedContributions, action.payload.contribution) === -1) {
                state.cachedContributions.push(action.payload.contribution)
            }
        })
        builder.addCase(fetchNextSlide.fulfilled, (state, action: any) => {
            if (findIndex(state.cachedContributions, action.payload.contribution) === -1) {
                state.cachedContributions.push(action.payload.contribution)
            }
            state.cachedNextContributions.push({
                meta: { context: action.meta.arg.context, id: action.meta.arg.id },
                contribution: action.payload.contribution
            })
        })
        builder.addCase(fetchPrevSlide.fulfilled, (state, action: any) => {
            if (findIndex(state.cachedContributions, action.payload.contribution) === -1) {
                state.cachedContributions.push(action.payload.contribution)
            }
            state.cachedPrevContributions.push({
                meta: { context: action.meta.arg.context, id: action.meta.arg.id },
                contribution: action.payload.contribution
            })
        })
    }
})

export const { clearCache } = cacheSlice.actions

export default cacheSlice.reducer
