import { asyncComponent } from '@jaredpalmer/after'

import paths from './paths'
import NotFound from './pages/notFound/notFound'

const routes = [
    {
        exact: true,
        path: `(${paths.index.path}|${paths.index.path + 'ru'}|${paths.index.path + 'en'})`,
        component: asyncComponent({
            loader: () => import('./pages/index/index'),
            chunkName: 'index'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.indexPhotoFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexPhotoFemAndCouple'),
            chunkName: 'indexPhotoFemAndCouple'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.indexPhotoFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexPhotoFemAndCouple'),
            chunkName: 'indexPhotoFemAndCouple'
        })
    },
    {
        exact: true,
        path: '/en' + paths.indexPhotoFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexPhotoFemAndCouple'),
            chunkName: 'indexPhotoFemAndCouple'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.indexVideoFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexVideoFemAndCouple'),
            chunkName: 'indexVideoFemAndCouple'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.indexVideoFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexVideoFemAndCouple'),
            chunkName: 'indexVideoFemAndCouple'
        })
    },
    {
        exact: true,
        path: '/en' + paths.indexVideoFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexVideoFemAndCouple'),
            chunkName: 'indexVideoFemAndCouple'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.indexCertifiedFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexCertifiedFemAndCouple'),
            chunkName: 'indexCertifiedFemAndCouple'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.indexCertifiedFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexCertifiedFemAndCouple'),
            chunkName: 'indexCertifiedFemAndCouple'
        })
    },
    {
        exact: true,
        path: '/en' + paths.indexCertifiedFemAndCouple.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexCertifiedFemAndCouple'),
            chunkName: 'indexCertifiedFemAndCouple'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.indexBestPhotos.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexBestPhotos'),
            chunkName: 'indexBestPhotos'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.indexBestPhotos.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexBestPhotos'),
            chunkName: 'indexBestPhotos'
        })
    },
    {
        exact: true,
        path: '/en' + paths.indexBestPhotos.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexBestPhotos'),
            chunkName: 'indexBestPhotos'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.indexPhotoAndVideoMale.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexPhotoAndVideoMale'),
            chunkName: 'indexPhotoAndVideoMale'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.indexPhotoAndVideoMale.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexPhotoAndVideoMale'),
            chunkName: 'indexPhotoAndVideoMale'
        })
    },
    {
        exact: true,
        path: '/en' + paths.indexPhotoAndVideoMale.path,
        component: asyncComponent({
            loader: () => import('./pages/index/indexPhotoAndVideoMale'),
            chunkName: 'indexPhotoAndVideoMale'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.payment.path,
        component: asyncComponent({
            loader: () => import('./pages/payment/payment'),
            chunkName: 'payment'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.payment.path,
        component: asyncComponent({
            loader: () => import('./pages/payment/payment'),
            chunkName: 'payment'
        })
    },
    {
        exact: true,
        path: '/en' + paths.payment.path,
        component: asyncComponent({
            loader: () => import('./pages/payment/payment'),
            chunkName: 'payment'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.search.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'search'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.search.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'search'
        })
    },
    {
        exact: true,
        path: '/en' + paths.search.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'search'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.searchVideo.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchVideo'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.searchVideo.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchVideo'
        })
    },
    {
        exact: true,
        path: '/en' + paths.searchVideo.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchVideo'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.searchPhoto.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchPhoto'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.searchPhoto.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchPhoto'
        })
    },
    {
        exact: true,
        path: '/en' + paths.searchPhoto.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchPhoto'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.searchContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributions'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.searchContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributions'
        })
    },
    {
        exact: true,
        path: '/en' + paths.searchContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributions'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.searchContributionsPhoto.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributionsPhoto'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.searchContributionsPhoto.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributionsPhoto'
        })
    },
    {
        exact: true,
        path: '/en' + paths.searchContributionsPhoto.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributionsPhoto'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.searchContributionsVideo.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributionsVideo'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.searchContributionsVideo.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributionsVideo'
        })
    },
    {
        exact: true,
        path: '/en' + paths.searchContributionsVideo.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/tags'),
            chunkName: 'searchContributionsVideo'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.recherche.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/paginationSearch'),
            chunkName: 'recherche'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.recherche.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/paginationSearch'),
            chunkName: 'recherche'
        })
    },
    {
        exact: true,
        path: '/en' + paths.recherche.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/paginationSearch'),
            chunkName: 'recherche'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.tags.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/paginationTags'),
            chunkName: 'tags'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.tags.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/paginationTags'),
            chunkName: 'tags'
        })
    },
    {
        exact: true,
        path: '/en' + paths.tags.path,
        component: asyncComponent({
            loader: () => import('./pages/tags/paginationTags'),
            chunkName: 'tags'
        })
    },
    // NEW ROUT
    {
        exact: true,
        path: paths.profiles.path,
        component: asyncComponent({
            loader: () => import('./pages/profiles/profiles'),
            chunkName: 'profiles'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profiles.path,
        component: asyncComponent({
            loader: () => import('./pages/profiles/profiles'),
            chunkName: 'profiles'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profiles.path,
        component: asyncComponent({
            loader: () => import('./pages/profiles/profiles'),
            chunkName: 'profiles'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.geo.path,
        component: asyncComponent({
            loader: () => import('./pages/geo/geo'),
            chunkName: 'geo'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.geo.path,
        component: asyncComponent({
            loader: () => import('./pages/geo/geo'),
            chunkName: 'geo'
        })
    },
    {
        exact: true,
        path: '/en' + paths.geo.path,
        component: asyncComponent({
            loader: () => import('./pages/geo/geo'),
            chunkName: 'geo'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.contact.path,
        component: asyncComponent({
            loader: () => import('./pages/contact/contact'),
            chunkName: 'contact'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.contact.path,
        component: asyncComponent({
            loader: () => import('./pages/contact/contact'),
            chunkName: 'contact'
        })
    },
    {
        exact: true,
        path: '/en' + paths.contact.path,
        component: asyncComponent({
            loader: () => import('./pages/contact/contact'),
            chunkName: 'contact'
        })
    },
    // NEW ROUT
    {
        exact: true,
        path: paths.profile.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profile'),
            chunkName: 'profile'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profile.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profile'),
            chunkName: 'profile'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profile.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profile'),
            chunkName: 'profile'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.profileAlbum.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileAlbum'),
            chunkName: 'profileAlbum'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profileAlbum.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileAlbum'),
            chunkName: 'profileAlbum'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profileAlbum.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileAlbum'),
            chunkName: 'profileAlbum'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.profileContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileContributions'),
            chunkName: 'profileContributions'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profileContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileContributions'),
            chunkName: 'profileContributions'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profileContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileContributions'),
            chunkName: 'profileContributions'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.profileGuestbook.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileGuestbook'),
            chunkName: 'profileGuestbook'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profileGuestbook.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileGuestbook'),
            chunkName: 'profileGuestbook'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profileGuestbook.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileGuestbook'),
            chunkName: 'profileGuestbook'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.profileFollowers.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followers'),
            chunkName: 'profileFollowers'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profileFollowers.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followers'),
            chunkName: 'profileFollowers'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profileFollowers.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followers'),
            chunkName: 'profileFollowers'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.profilePosts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/posts'),
            chunkName: 'profilePosts'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profilePosts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/posts'),
            chunkName: 'profilePosts'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profilePosts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/posts'),
            chunkName: 'profilePosts'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myWallet.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/wallet'),
            chunkName: 'myWallet'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myWallet.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/wallet'),
            chunkName: 'myWallet'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myWallet.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/wallet'),
            chunkName: 'myWallet'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myVirtualGifts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/virtualGifts'),
            chunkName: 'myVirtualGifts'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myVirtualGifts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/virtualGifts'),
            chunkName: 'myVirtualGifts'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myVirtualGifts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/virtualGifts'),
            chunkName: 'myVirtualGifts'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.profileGifts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/virtualGifts'),
            chunkName: 'profileGifts'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.profileGifts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/virtualGifts'),
            chunkName: 'profileGifts'
        })
    },
    {
        exact: true,
        path: '/en' + paths.profileGifts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/virtualGifts'),
            chunkName: 'profileGifts'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.virtualGiftCatalog.path,
        component: asyncComponent({
            loader: () => import('./pages/virtualGiftCatalog/VirtualGiftCatalog'),
            chunkName: 'virtualGiftCatalog'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.virtualGiftCatalog.path,
        component: asyncComponent({
            loader: () => import('./pages/virtualGiftCatalog/VirtualGiftCatalog'),
            chunkName: 'virtualGiftCatalog'
        })
    },
    {
        exact: true,
        path: '/en' + paths.virtualGiftCatalog.path,
        component: asyncComponent({
            loader: () => import('./pages/virtualGiftCatalog/VirtualGiftCatalog'),
            chunkName: 'virtualGiftCatalog'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myWalletHistory.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/walletHistory'),
            chunkName: 'myWalletHistory'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myWalletHistory.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/walletHistory'),
            chunkName: 'myWalletHistory'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myWalletHistory.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/walletHistory'),
            chunkName: 'myWalletHistory'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.favoriteContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/favoriteContributions'),
            chunkName: 'favoriteContributions'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.favoriteContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/favoriteContributions'),
            chunkName: 'favoriteContributions'
        })
    },
    {
        exact: true,
        path: '/en' + paths.favoriteContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/favoriteContributions'),
            chunkName: 'favoriteContributions'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.favoriteContributors.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/favoriteContributors'),
            chunkName: 'favoriteContributors'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.favoriteContributors.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/favoriteContributors'),
            chunkName: 'favoriteContributors'
        })
    },
    {
        exact: true,
        path: '/en' + paths.favoriteContributors.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/favoriteContributors'),
            chunkName: 'favoriteContributors'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myProfile.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/member'),
            chunkName: 'member'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myProfile.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/member'),
            chunkName: 'member'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myProfile.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/member'),
            chunkName: 'member'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.memberMessagePmConversations.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmConversations'),
            chunkName: 'memberMessagePmConversations'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.memberMessagePmConversations.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmConversations'),
            chunkName: 'memberMessagePmConversations'
        })
    },
    {
        exact: true,
        path: '/en' + paths.memberMessagePmConversations.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmConversations'),
            chunkName: 'memberMessagePmConversations'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.memberMessagePmRequests.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmRequests'),
            chunkName: 'memberMessagePmRequests'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.memberMessagePmRequests.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmRequests'),
            chunkName: 'memberMessagePmRequests'
        })
    },
    {
        exact: true,
        path: '/en' + paths.memberMessagePmRequests.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmRequests'),
            chunkName: 'memberMessagePmRequests'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.memberMessagePmArchive.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmArchive'),
            chunkName: 'memberMessagePmArchive'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.memberMessagePmArchive.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmArchive'),
            chunkName: 'memberMessagePmArchive'
        })
    },
    {
        exact: true,
        path: '/en' + paths.memberMessagePmArchive.path,
        component: asyncComponent({
            loader: () => import('./pages/pm/PmArchive'),
            chunkName: 'memberMessagePmArchive'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.premiumHistory.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/premiumHistory'),
            chunkName: 'premiumHistory'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.premiumHistory.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/premiumHistory'),
            chunkName: 'premiumHistory'
        })
    },
    {
        exact: true,
        path: '/en' + paths.premiumHistory.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/premiumHistory'),
            chunkName: 'premiumHistory'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.notificationList.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/notificationsList'),
            chunkName: 'notificationsList'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.notificationList.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/notificationsList'),
            chunkName: 'notificationsList'
        })
    },
    {
        exact: true,
        path: '/en' + paths.notificationList.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/notificationsList'),
            chunkName: 'notificationsList'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.notificationSettings.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/notificationSettings'),
            chunkName: 'notificationsSettings'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.notificationSettings.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/notificationSettings'),
            chunkName: 'notificationsSettings'
        })
    },
    {
        exact: true,
        path: '/en' + paths.notificationSettings.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/notificationSettings'),
            chunkName: 'notificationsSettings'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.submitContribution.path,
        component: asyncComponent({
            loader: () => import('./pages/contribution/submitContribution'),
            chunkName: 'submitContribution'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.submitContribution.path,
        component: asyncComponent({
            loader: () => import('./pages/contribution/submitContribution'),
            chunkName: 'submitContribution'
        })
    },
    {
        exact: true,
        path: '/en' + paths.submitContribution.path,
        component: asyncComponent({
            loader: () => import('./pages/contribution/submitContribution'),
            chunkName: 'submitContribution'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myAlbum.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileAlbum'),
            chunkName: 'memberAlbum'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myAlbum.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileAlbum'),
            chunkName: 'memberAlbum'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myAlbum.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileAlbum'),
            chunkName: 'memberAlbum'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileContributions'),
            chunkName: 'memberContributions'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileContributions'),
            chunkName: 'memberContributions'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myContributions.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileContributions'),
            chunkName: 'memberContributions'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myGuestbook.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileGuestbook'),
            chunkName: 'memberGuestbook'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myGuestbook.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileGuestbook'),
            chunkName: 'memberGuestbook'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myGuestbook.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/profileGuestbook'),
            chunkName: 'memberGuestbook'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myFollowers.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followers'),
            chunkName: 'myFollowers'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myFollowers.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followers'),
            chunkName: 'myFollowers'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myFollowers.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followers'),
            chunkName: 'myFollowers'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myPosts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/posts'),
            chunkName: 'myPosts'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myPosts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/posts'),
            chunkName: 'myFPosts'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myPosts.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/posts'),
            chunkName: 'myPosts'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myFollowing.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followees'),
            chunkName: 'myFollowees'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myFollowing.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followees'),
            chunkName: 'myFollowees'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myFollowing.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/followees'),
            chunkName: 'myFollowees'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.myBlacklist.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/blacklist'),
            chunkName: 'myBlacklist'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.myBlacklist.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/blacklist'),
            chunkName: 'myBlacklist'
        })
    },
    {
        exact: true,
        path: '/en' + paths.myBlacklist.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/blacklist'),
            chunkName: 'myBlacklist'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.contribution.path,
        component: asyncComponent({
            loader: () => import('./pages/contribution/contribution'),
            chunkName: 'contribution'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.contribution.path,
        component: asyncComponent({
            loader: () => import('./pages/contribution/contribution'),
            chunkName: 'contribution'
        })
    },
    {
        exact: true,
        path: '/en' + paths.contribution.path,
        component: asyncComponent({
            loader: () => import('./pages/contribution/contribution'),
            chunkName: 'contribution'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.cgu.path,
        component: asyncComponent({
            loader: () => import('./pages/cgu/cgufr'),
            chunkName: 'cgu'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.cgu.path,
        component: asyncComponent({
            loader: () => import('./pages/cgu/cguen'),
            chunkName: 'cgu'
        })
    },
    {
        exact: true,
        path: '/en' + paths.cgu.path,
        component: asyncComponent({
            loader: () => import('./pages/cgu/cguen'),
            chunkName: 'cgu'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.archive.path,
        component: asyncComponent({
            loader: () => import('./pages/archive/archive'),
            chunkName: 'cgu'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.archive.path,
        component: asyncComponent({
            loader: () => import('./pages/archive/archive'),
            chunkName: 'cgu'
        })
    },
    {
        exact: true,
        path: '/en' + paths.archive.path,
        component: asyncComponent({
            loader: () => import('./pages/archive/archive'),
            chunkName: 'cgu'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.video.path,
        component: asyncComponent({
            loader: () => import('./pages/contributionMaterial/contributionMaterial'),
            chunkName: 'video'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.video.path,
        component: asyncComponent({
            loader: () => import('./pages/contributionMaterial/contributionMaterial'),
            chunkName: 'video'
        })
    },
    {
        exact: true,
        path: '/en' + paths.video.path,
        component: asyncComponent({
            loader: () => import('./pages/contributionMaterial/contributionMaterial'),
            chunkName: 'video'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.photo.path,
        component: asyncComponent({
            loader: () => import('./pages/contributionMaterial/contributionMaterial'),
            chunkName: 'photo'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.photo.path,
        component: asyncComponent({
            loader: () => import('./pages/contributionMaterial/contributionMaterial'),
            chunkName: 'photo'
        })
    },
    {
        exact: true,
        path: '/en' + paths.photo.path,
        component: asyncComponent({
            loader: () => import('./pages/contributionMaterial/contributionMaterial'),
            chunkName: 'photo'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.passwordRecover.path,
        component: asyncComponent({
            loader: () => import('./pages/passwordRecover/passwordRecover'),
            chunkName: 'passwordRecover'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.passwordRecover.path,
        component: asyncComponent({
            loader: () => import('./pages/passwordRecover/passwordRecover'),
            chunkName: 'passwordRecover'
        })
    },
    {
        exact: true,
        path: '/en' + paths.passwordRecover.path,
        component: asyncComponent({
            loader: () => import('./pages/passwordRecover/passwordRecover'),
            chunkName: 'passwordRecover'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.userActivation.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/userActivation'),
            chunkName: 'userActivation'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.userActivation.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/userActivation'),
            chunkName: 'userActivation'
        })
    },
    {
        exact: true,
        path: '/en' + paths.userActivation.path,
        component: asyncComponent({
            loader: () => import('./pages/profile/userActivation'),
            chunkName: 'userActivation'
        })
    },

    // NEW ROUT
    {
        exact: true,
        path: paths.stub.path,
        component: asyncComponent({
            loader: () => import('./pages/stub/stub'),
            chunkName: 'stub'
        })
    },
    {
        exact: true,
        path: '/ru' + paths.stub.path,
        component: asyncComponent({
            loader: () => import('./pages/stub/stub'),
            chunkName: 'stub'
        })
    },
    {
        exact: true,
        path: '/en' + paths.stub.path,
        component: asyncComponent({
            loader: () => import('./pages/stub/stub'),
            chunkName: 'stub'
        })
    },
    {
        component: NotFound
    }
]

export default routes
