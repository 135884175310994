import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getProfile } from '../../../api/profile'
import { RootState } from '../../../store/rootReducer'
import { PmCommonType } from '../../../store/common/commonSliceTyping'
import { deletePm_commonEndedReason } from '../../../store/common/commonSlice'
import './videoCallRequest.sass'

interface Props {
    userId: number
    call_id: string
}

const CallEnded = ({ userId }: Props) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const pm_common = useSelector<RootState, PmCommonType>((state) => state.common.pm_common)

    const [user, setUser] = useState(null)

    useEffect(() => {
        getProfile({ id: userId, short: 1 })
            .then((response) => {
                setUser(response.user)
            })
            .catch((error) => {
                console.log(error.message)
            })
        setTimeout(() => dispatch(deletePm_commonEndedReason()), 3000)
    }, [])

    return (
        <div className={`videoCallRequest`}>
            <div className={'infoBlock'}>{user ? user.nick : userId}</div>
            <img className={'avatar'} src={user ? user.avatar_url : require('./../../../assets/404.png')} />
            <div className={'infoBlock strong'}>{t(pm_common.ended_reason)}</div>
        </div>
    )
}

export default CallEnded
