// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/ic-checked.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox input[type=checkbox] {\n  display: none;\n}\n.checkbox label {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  cursor: pointer;\n  line-height: 18px;\n}\n.checkbox label:before {\n  content: \"\";\n  width: 18px;\n  height: 18px;\n  display: block;\n  min-width: 18px;\n  margin-right: 10px;\n  border-radius: 2px;\n  border: var(--grey-light-border);\n  -webkit-transition: all 0.25s ease;\n  -moz-transition: all 0.25s ease;\n  transition: all 0.25s ease;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n}\n.checkbox input[type=checkbox]:checked + label:before {\n  border-color: var(--main-theme-color);\n  background-color: var(--main-theme-color);\n}", "",{"version":3,"sources":["webpack://./checkboxInput.sass"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAEE;EACE,oBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA,aAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAEI;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,gCAAA;EACA,kCAAA;EAAA,+BAAA;EAAA,0BAAA;EACA,oEAAA;AAAN;AAGI;EACE,qCAAA;EACA,yCAAA;AADN","sourcesContent":[".checkbox\r\n  input[type='checkbox']\r\n    display: none\r\n\r\n  label\r\n    display: flex\r\n    cursor: pointer\r\n    line-height: 18px\r\n\r\n    &:before\r\n      content: ''\r\n      width: 18px\r\n      height: 18px\r\n      display: block\r\n      min-width: 18px\r\n      margin-right: 10px\r\n      border-radius: 2px\r\n      border: var(--grey-light-border)\r\n      transition: all .25s ease\r\n      background: url(\"../../../assets/ic-checked.svg\") no-repeat center\r\n\r\n  input[type=\"checkbox\"]:checked + label\r\n    &:before\r\n      border-color: var(--main-theme-color)\r\n      background-color: var(--main-theme-color)"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
