import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Button from '../../ui/button'
import { getProfile } from '../../../api/profile'
import './videoCallRequest.sass'

interface Props {
    userId: number
    call_id: string
}

const NewVideoCall = ({ userId, call_id }: Props) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const [user, setUser] = useState(null)

    const handleCancelCall = () => {
        dispatch({ type: 'PM_ACTION_VIDEO_CALL_CANCEL', conversation_uid: userId, call_id: call_id })
    }

    useEffect(() => {
        getProfile({ id: userId, short: 1 })
            .then((response) => {
                setUser(response.user)
            })
            .catch((error) => {
                console.log(error.message)
            })
    }, [])

    return (
        <div className={`videoCallRequest`}>
            <img className={'avatar'} src={user ? user.avatar_url : require('./../../../assets/404.png')} />
            <div className={'infoBlock'}>
                {t('Video call to')}
                {user ? user.nick : userId}
            </div>
            <div className={'cancelWrapper'}>
                <Button label={t('Cancel')} onClick={() => handleCancelCall()} />
            </div>
        </div>
    )
}

export default NewVideoCall
