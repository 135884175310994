import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { getIndexContributions } from '../../api/contributions'
import { ArchiveState } from './archiveTypings'
import { FetchContributionsPayload, FetchIndexContributions } from '../contributions/contributionsTypings'

const initialState: ArchiveState = {
    list: [],
    isLoading: false,
    total_count: undefined,
    error: null
}

export const fetchArchiveContributions = createAsyncThunk(
    'fetchArchiveContributions',
    async (params: FetchIndexContributions) => {
        return getIndexContributions(params)
    }
)

const archiveSlice = createSlice({
    name: 'archive',
    initialState,
    reducers: {
        clearArchivesList(state) {
            state.list = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchArchiveContributions.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchArchiveContributions.fulfilled, (state, action: PayloadAction<FetchContributionsPayload>) => {
                state.isLoading = false
                state.total_count = Number(action.payload.total_count)

                action.payload.list.forEach((item) => {
                    if (state.list.findIndex((listItem) => listItem.id === item.id) === -1)
                        state.list = state.list.concat([item])
                })
            })
            .addCase(fetchArchiveContributions.rejected, (state) => {
                state.isLoading = false
            })
    }
})

export const { clearArchivesList } = archiveSlice.actions
export default archiveSlice.reducer
