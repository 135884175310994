import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { GeoState, FetchGeoPayload, FetchGeoList, UpdateGeoPayload, UserLocationsTyping, Options } from './geoTypings'
import { getGeoList, getGeoOption, updateMyPosition } from '../../api/geo'

const initialState: GeoState = {
    list: [],
    message: null,
    filters: null,
    visitedGeo: false,
    hidePosition: false,
    userLocations: {
        lat: 48.85,
        lon: 2.35
    },
    loading: false
}

export const fetchGeo = createAsyncThunk('fetchGeo', async (params: FetchGeoList) => {
    return await getGeoList(params)
})

export const updateMyPositionAction = createAsyncThunk(
    'updateMyPositionAction',
    async (params: UserLocationsTyping) => {
        return await updateMyPosition(params)
    }
)

export const fetchGeoOptions = createAsyncThunk('fetchGeoOptions', async () => {
    return getGeoOption()
})

const geoSlice = createSlice({
    name: 'geo',
    initialState,
    reducers: {
        updateUserPosition(state, action: PayloadAction<{ lat: number; lon: number }>) {
            state.userLocations = action.payload
        },
        updateFilters(state, action: PayloadAction<Options>) {
            state.filters = Object.assign(state.filters, action.payload)
        },
        setCheckboxValue(state, action: PayloadAction<number>) {
            state.filters.shareMyLocation = action.payload
        },
        updateMessage(state, action: PayloadAction<string>) {
            state.message = action.payload
        },
        setDefaultFilters(state) {
            state.filters = {
                shareMyLocation: 0,
                distance: 300,
                precision: 3000,
                age: 0,
                gender: 0,
                followees: 0,
                followers: 0,
                message: null
            }
        },
        setHideFilters(state, action: PayloadAction<boolean>) {
            state.hidePosition = action.payload
        },
        setVisitedGeo(state) {
            state.visitedGeo = true
            localStorage.setItem('geolocationVisitedInThisBrowser', 'true')
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGeo.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchGeo.fulfilled, (state, action: PayloadAction<FetchGeoPayload>) => {
            state.loading = false
            // state.total = action.payload.total
            state.list = action.payload.data
        })
        builder.addCase(fetchGeo.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(updateMyPositionAction.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchGeoOptions.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchGeoOptions.fulfilled, (state, action: PayloadAction<UpdateGeoPayload>) => {
            state.loading = false
            state.visitedGeo = true
            if (JSON.stringify(state.message) !== JSON.stringify(action.payload.message))
                state.message = action.payload.message
            if (JSON.stringify(state.filters) !== JSON.stringify(action.payload.options))
                state.filters = action.payload.options
            if (!action.payload.options) {
                state.filters = {
                    shareMyLocation: 0,
                    distance: 300,
                    precision: 3000,
                    age: 0,
                    gender: 0,
                    followees: 0,
                    followers: 0,
                    message: null
                }
            }
        })
        builder.addCase(updateMyPositionAction.fulfilled, (state, action: PayloadAction<UpdateGeoPayload>) => {
            state.loading = false
            if (JSON.stringify(state.message) !== JSON.stringify(action.payload.message))
                state.message = action.payload.message
            if (JSON.stringify(state.filters) !== JSON.stringify(action.payload.options))
                state.filters = action.payload.options
            if (!action.payload.options) {
                state.filters = {
                    shareMyLocation: 0,
                    distance: 300,
                    precision: 3000,
                    age: 0,
                    gender: 0,
                    followees: 0,
                    followers: 0,
                    message: null
                }
            }
        })
        builder.addCase(updateMyPositionAction.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(fetchGeoOptions.rejected, (state) => {
            state.loading = false
            state.filters = {
                shareMyLocation: 0,
                distance: 300,
                precision: 3000,
                age: 0,
                gender: 0,
                followees: 0,
                followers: 0,
                message: null
            }
        })
    }
})

export const {
    updateUserPosition,
    updateFilters,
    setCheckboxValue,
    updateMessage,
    setDefaultFilters,
    setHideFilters,
    setVisitedGeo
} = geoSlice.actions
export default geoSlice.reducer
