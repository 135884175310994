import get from 'lodash/get'
import isNull from 'lodash/isNull'

const formDataToRequestParams = <P>(params: P, upload = false): FormData => {
    const requestParams = new FormData()

    if (upload) {
        // @ts-ignore
        requestParams.set('files[]', params.file)
    } else {
        Object.keys(params).forEach((name) => {
            const value = get(params, name)
            requestParams.set(name, isNull(value) ? value : value.toString())
        })
    }

    return requestParams
}

const uploadContributionFormDataToRequestParams = <P>(params: P, upload = false): FormData => {
    const requestParams = new FormData()

    if (upload) {
        // @ts-ignore
        requestParams.set('files[]', params.file)
    } else {
        Object.keys(params).forEach((name) => {
            const value = get(params, name)
            console.log(value)
            if (Array.isArray(value)) {
                requestParams.set('comments', JSON.stringify(value))
            } else {
                requestParams.set(name, isNull(value) ? value : value.toString())
            }
        })
    }

    return requestParams
}

export { formDataToRequestParams, uploadContributionFormDataToRequestParams }
