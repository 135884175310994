import { createContext } from 'react'

export interface FilterButtonProps {
    filterButton?: boolean
    setFilterButton?: React.Dispatch<boolean>
    isNecessaryToShowFilters?: boolean
    setIsNecessaryToShowFilters?: React.Dispatch<boolean>
}

export const FilterButtonContext = createContext<FilterButtonProps>({})
