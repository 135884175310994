import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Button from './../../ui/button/index'

import { getProfile } from '../../../api/profile'
import { addHiddenCaller } from '../../../store/common/commonSlice'
import { createLocalizedPath } from '../../../utils/localizationHelpers'
import passiveSupported from '../../../utils/passiveSupported'
import paths from '../../../paths'
import './videoCallRequest.sass'

interface Props {
    userId: number
    call_id: string
}

const VideoCallRequest = ({ userId, call_id }: Props) => {
    const [t] = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const [user, setUser] = useState(null)
    const [accepted, setAccepted] = useState(false)

    const [initPosition, setInitPosition] = useState(null)
    const [endPosition, setEndPosition] = useState(null)
    const [movingPosition, setMovingPosition] = useState(null)
    const [bgOfPopup, setBgOfPopup] = useState('')
    const ref = useRef(null)

    const zoneOfNonSwiping = 50

    const touchStarting = (event: TouchEvent) => {
        setInitPosition(event.touches[0].clientX)
    }
    const touchEnding = (event: TouchEvent) => {
        setEndPosition(event.changedTouches[0].clientX)
    }

    const touchMoving = (event: TouchEvent) => {
        setMovingPosition(event.changedTouches[0].clientX)
    }

    const handleAcceptCalling = () => {
        setAccepted(true)
        history.push({
            pathname: createLocalizedPath(paths.memberMessagePmConversations.path),
            search: `?pm=` + userId
        })
    }

    const handleDeclineCalling = () => {
        dispatch(addHiddenCaller({ id: userId }))
        dispatch({ type: 'PM_ACTION_VIDEO_CALL_DECLINE', call_id: call_id })
    }

    useEffect(() => {
        if (movingPosition - initPosition < -zoneOfNonSwiping && bgOfPopup !== 'blueBg') setBgOfPopup('blueBg')
        if (
            movingPosition - initPosition <= zoneOfNonSwiping &&
            movingPosition - initPosition >= -zoneOfNonSwiping &&
            bgOfPopup !== 'whiteBg'
        ) {
            setBgOfPopup('whiteBg')
        }
        if (movingPosition - initPosition > zoneOfNonSwiping && bgOfPopup !== 'redBg') setBgOfPopup('redBg')
    }, [movingPosition])

    useEffect(() => {
        if (endPosition - initPosition < -zoneOfNonSwiping) handleAcceptCalling()
        if (endPosition - initPosition > zoneOfNonSwiping) handleDeclineCalling()
    }, [endPosition])

    useEffect(() => {
        getProfile({ id: userId, short: 1 })
            .then((response) => {
                setUser(response.user)
            })
            .catch((error) => {
                console.log(error.message)
            })
        ref.current.addEventListener('touchstart', touchStarting, passiveSupported)
        ref.current.addEventListener('touchend', touchEnding, passiveSupported)
        ref.current.addEventListener('touchmove', touchMoving, passiveSupported)
        return () => {
            if (ref.current) {
                ref.current.removeEventListener('touchstart', touchStarting)
                ref.current.removeEventListener('touchend', touchEnding)
                ref.current.removeEventListener('touchmove', touchMoving)
            }
        }
    }, [])

    useEffect(() => {
        if (accepted && location.search.includes(`pm=${userId}`))
            dispatch({ type: 'PM_ACTION_VIDEO_CALL_ANSWER', conversation_uid: userId, call_id: call_id })
    }, [accepted])
    return (
        <div className={`videoCallRequest ${bgOfPopup}`} ref={ref}>
            <div className={'infoBlock strong'}>{t('Incoming video call')}</div>
            <img className={'avatar'} src={user ? user.avatar_url : require('./../../../assets/404.png')} />
            <div className={'infoBlock'}>{user ? user.nick : userId}</div>
            <div className={'buttons'}>
                <Button label={t('Answer')} onClick={handleAcceptCalling} />{' '}
                <Button label={t('Decline')} onClick={handleDeclineCalling} />
            </div>
        </div>
    )
}

export default VideoCallRequest
