/* eslint i18next/no-literal-string: 0 */
import React, { useEffect, useState, memo, useRef, Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Popup from '../ui/popup'
import LangLinks from '../ui/langLinks'

import { RootState } from '../../store/rootReducer'
import {
    switchMenu,
    closeMenu,
    switchPageLoading,
    unfixAppContainer,
    openMenu,
    pageLoaded
} from '../../store/common/commonSlice'
import isEmpty from 'lodash/isEmpty'
import './menu.sass'
import { Member } from '../../store/commonTypes'

import MenuLinks from './menuLinks'
import BobLinks from './bobLinks'
import PaginationLoader from '../ui/paginationLoader/PaginationLoader'
import MenuButtonContainer from './menuButtonContainer'

const MenuLogin = React.lazy(() => import('./login'))
const MenuSignUp = React.lazy(() => import('./signUp'))
const MenuActivation = React.lazy(() => import('./activate'))
const MenuForgotPassword = React.lazy(() => import('./menuForgotPassword'))
const UserBlock = React.lazy(() => import('./userBlock'))
const MenuChangePassword = React.lazy(() => import('./menuChangePassword'))

const Menu = () => {
    const location = useLocation()
    const dispatch = useDispatch()

    const isMenuOpened = useSelector<RootState, boolean>((state) => state.common.isMenuOpened)
    const isChangingPassword = useSelector<RootState, boolean>((state) => state.common.isChangingPassword)

    const isFirstLoadingRef = useRef(true)

    const user = useSelector<RootState, Member | null>((state) => state.member.user)

    const [currentForm, setCurrentForm] = useState('login')

    useEffect(() => {
        if (isFirstLoadingRef.current) {
            isFirstLoadingRef.current = false
        } else {
            dispatch(closeMenu())
            dispatch(switchPageLoading())
        }
    }, [location])

    useEffect(() => {
        const query = location.search
        switch (true) {
            case query.includes('?password_change') && isEmpty(user):
                setCurrentForm('login')
                dispatch(openMenu())
                break
            case query.includes('?password_change'):
                setCurrentForm('passwordChange')
                dispatch(openMenu())
                dispatch(pageLoaded())
                break
            case query.includes('?restore'):
                setCurrentForm('restore')
                dispatch(openMenu())
                break
            case query.includes('?login'):
                setCurrentForm('login')
                dispatch(openMenu())
                break
            case query.includes('?register'):
                setCurrentForm('signup')
                dispatch(openMenu())
                break
            case query.includes('?activate'):
                setCurrentForm('activate')
                dispatch(openMenu())
                break
            case query.includes('?delete-account'):
                dispatch(openMenu())
                break
        }
        // eslint-disable-next-line
    }, [location, user])

    useEffect(() => {
        if (isChangingPassword) {
            setCurrentForm('passwordChange')
            dispatch(openMenu())
        } else {
            if (currentForm !== 'login') setCurrentForm('login')
        }
        // eslint-disable-next-line
    }, [location, isChangingPassword])

    useEffect(() => {
        if (user && currentForm === 'signup') setCurrentForm('activate')
    }, [user])

    return isMenuOpened ? (
        <Popup
            onClose={() => {
                dispatch(switchMenu())
                dispatch(unfixAppContainer())
            }}
            classname="menu"
        >
            <div className="menuContent">
                {(isEmpty(user) || currentForm === 'passwordChange') && (
                    <div className="menuSection">
                        {currentForm === 'login' && (
                            <Suspense fallback={<PaginationLoader />}>
                                <MenuLogin switchForm={setCurrentForm} />
                            </Suspense>
                        )}
                        {currentForm === 'signup' && (
                            <Suspense fallback={<PaginationLoader />}>
                                <MenuSignUp switchForm={setCurrentForm} />
                            </Suspense>
                        )}
                        {currentForm === 'restore' && (
                            <Suspense fallback={<PaginationLoader />}>
                                <MenuForgotPassword switchForm={setCurrentForm} />
                            </Suspense>
                        )}
                        {currentForm === 'passwordChange' && (
                            <Suspense fallback={<PaginationLoader />}>
                                <MenuChangePassword />
                            </Suspense>
                        )}
                    </div>
                )}
                {!isEmpty(user) && !user.active && (
                    <div className="menuSection">
                        {currentForm === 'login' && (
                            <Suspense fallback={<PaginationLoader />}>
                                <MenuLogin switchForm={setCurrentForm} activate />
                            </Suspense>
                        )}
                        {currentForm === 'activate' && (
                            <Suspense fallback={<PaginationLoader />}>
                                <MenuActivation switchForm={setCurrentForm} user={user} />
                            </Suspense>
                        )}
                        {currentForm === 'restore' && (
                            <Suspense fallback={<PaginationLoader />}>
                                <MenuForgotPassword switchForm={setCurrentForm} />
                            </Suspense>
                        )}
                    </div>
                )}

                {!isEmpty(user) && !!user.active && currentForm !== 'passwordChange' && (
                    <div className="menuSection">
                        <Suspense fallback={<PaginationLoader />}>
                            <UserBlock user={user} />
                        </Suspense>
                    </div>
                )}
                <MenuLinks />
                <BobLinks />
                <LangLinks />
            </div>
        </Popup>
    ) : (
        <MenuButtonContainer />
    )
}

export default memo(Menu)
