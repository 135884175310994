/* eslint-disable */
export default function Instanoti(config) {
    this.connect = function () {
        try {
            var socket = new WebSocket(this.config.server_uri)
        } catch (e) {
            return this.error_socket_error()
        }

        const _this = this

        socket.onopen = function () {
            _this.on_socket_open()
            clearInterval(_this.ping_interval)
            _this.ping_interval = setInterval(() => {
                _this.send_ping()
            }, 30000)
        }

        socket.onerror = function () {
            return _this.error_socket_error()
        }

        socket.onmessage = function (event) {
            _this.on_socket_message(event.data)
        }

        socket.onclose = function () {
            clearInterval(_this.ping_interval)
            _this.status = 'closed'
            _this.logged = false
            _this.fire_event('instanoti:close', { instance: _this })
        }

        this.socket = socket
    }

    this.on_socket_open = function () {
        this.fire_event('instanoti:on_open', { instance: this })
        this.reconnecting = false
        this.reconnect_interval = clearInterval(this.reconnect_interval)
    }

    this.error_socket_error = function () {
        this.fire_event('instanoti:on_error', { instance: this })
    }

    this.on = function (event, func) {
        if (!this.events[event]) {
            this.events[event] = []
        }

        this.events[event].push(func)
    }

    this.fire_event = function (event, data) {
        const handlers = this.events[event]
        if (handlers) {
            for (let i = handlers.length - 1; i >= 0; i--) {
                handlers[i](data)
            }
        }
    }

    this.on_socket_message = function (data) {
        try {
            const response = JSON.parse(data)
            if (response.type === 'notification') {
                _this.fire_event('instanoti:on_' + response.data.type, response.data)
            }
        } catch (err) {
            _this.error_unrecognized_response(err, data)
        }
    }

    this.error_unrecognized_response = function (err, data) {
        console.error('WS message not recognized', err, data)
    }

    this.subscribe = function () {}

    this.send_ping = function () {
        this.socket.send('ping')
    }

    this.config = config
    this.status = 'disconnected'
    this.ping_interval = 0
    this.reconnect_interval = 0
    this.reconnecting = false
    this.events = {}

    // set callbacks
    for (const key in config) {
        if (typeof config[key] === 'function') {
            this.on('instanoti:' + key, config[key])
        }
    }

    if (config.reconnect) {
        var _this = this
        this.on('instanoti:close', function () {
            if (!_this.reconnecting) {
                _this.reconnecting = true
                _this.reconnect_interval = setInterval(function () {
                    _this.connect()
                }, 30000)
                //_this.connect()
            }
        })
    }

    this.connect()
}
