import * as React from 'react'
import { useTranslation } from 'react-i18next'

import './checkboxInput.sass'

interface Props {
    id?: string
    label?: string
    checked?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    noWrap?: boolean
}

const CheckboxInput: React.FunctionComponent<Props> = ({ id, label, checked, onChange, noWrap }: Props) => {
    const [t] = useTranslation()
    return (
        <div className="checkbox">
            <input type="checkbox" id={id} checked={checked} onChange={onChange} />
            <label htmlFor={id} style={noWrap ? { whiteSpace: 'nowrap' } : {}}>
                {t(label)}
            </label>
        </div>
    )
}

export default CheckboxInput
