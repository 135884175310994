import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import LangLinks from '../ui/langLinks'
import CheckboxInput from '../ui/checkboxInput'
import Button from '../ui/button'

import { createLocalizedPath } from '../../utils/localizationHelpers'
import paths from '../../paths'
import Cookies from 'js-cookie'
import './disclaimer.sass'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { addNotification } from '../../store/member/memberSlice'
import moment from 'moment-mini'

interface Props {
    setDailyCookie: React.Dispatch<boolean>
}

const Disclaimer: React.FunctionComponent<Props> = ({ setDailyCookie }: Props) => {
    const [t] = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()

    const [checkBox, setCheckBox] = useState(true)
    const [showError, setShowError] = useState(false)
    const exceptions = ['cgu']
    const isPageException = exceptions.includes(location.pathname.split('/').reverse()[0])
    const loggedIn = useSelector((state: RootState) => state.member.loggedIn)
    const user = useSelector((state: RootState) => state.member.user)

    const handleEnterButton = () => {
        if (checkBox) {
            Cookies.set('Visited', 'yes', { expires: 1 })
            setDailyCookie(false)
            if (loggedIn)
                if (user.expiration_date_ts) {
                    const amountOfPremium = -moment().diff(user.expiration_date_ts * 1000, 'days')
                    if (amountOfPremium > 0 && amountOfPremium < 7) {
                        setTimeout(
                            () =>
                                dispatch(
                                    addNotification({
                                        type: 'premiumEnds',
                                        id: Number(new Date()),
                                        duration: amountOfPremium
                                    })
                                ),
                            60000
                        )
                    } else {
                        if (amountOfPremium < 0 && amountOfPremium > -7) {
                            setTimeout(
                                () =>
                                    dispatch(
                                        addNotification({
                                            type: 'premiumEnded',
                                            id: Number(new Date())
                                        })
                                    ),
                                60000
                            )
                        }
                    }
                }
        } else {
            setShowError(true)
        }
    }

    return (
        <div className={isPageException ? 'disclaimerPopup notAvailable' : 'disclaimerPopup'} id="dailyDialog">
            <div className="modalDialog">
                <div className="modalHeader">
                    <p className="paragraph_header">{t('Site fo adults only!')} </p>
                    <LangLinks />
                </div>
                <i>{t('According to the French law')}</i>
                <div className="contentModalDisclaimer">
                    <p>{t('This website is reserved for a major and warned public')}.</p>
                    <p>
                        {t(
                            'It contains texts, pornographic photos and videos prohibited to minors and which may be offensive to some sensibilities'
                        )}
                        .
                    </p>
                    <p>
                        {t(
                            'It is strongly discouraged to sensitive, fragile and impressionable people to access this site; minors mustnt have access under any circumstances'
                        )}
                        .
                    </p>
                    <p>
                        {t(
                            'I validate the data contained in the following paragraphs after its careful reading, and I agree fully and completely, certifying to fulfill all mentioned conditions, by checking the checkboxprovided'
                        )}
                        .
                    </p>

                    <p className="paragraph_header">{t('I certify to')}</p>
                    <p>- {t('Be major according to the Act of my country, and to the French law')},</p>
                    <p>
                        -{' '}
                        {t(
                            'That the laws of my state or my country authorize me to access this site and that bobtvfr has the right to provide me with such data'
                        )}
                        ,
                    </p>
                    <p>- {t('Being informed of pornographic character of content on the server')},</p>

                    <p className="paragraph_header">{t('I declare and acknowledge explicitly')}</p>
                    <p>
                        -{' '}
                        {t(
                            'Not to be shocked by any type of sexuality, any text, any pornographic pictures or video, and I prohibit myself to prosecute the authors of bobtvfr due to the data on this site or its consequences'
                        )}
                        ;
                    </p>
                    <p>
                        -{' '}
                        {t(
                            'To consult this server only for personal needs and without any involvement of a private company, a government agency, or any other public or private entity'
                        )}
                        ;
                    </p>

                    <p className="paragraph_header">{t('I pledge on my honor')}</p>
                    <p>- {t('never announce the existence of the server to minors')},</p>
                    <p>
                        -{' '}
                        {t(
                            'never allow minors to access this server and use all conducive means to prevent access this server by minors'
                        )}
                        ,
                    </p>
                    <p>- {t('never broadcast all or a part of the content published on this server to minors')},</p>
                    <p>
                        -{' '}
                        {t(
                            'to assume my responsibility in case that a minor would have access to the server because of my negligence or my recklessness (lack of protection of my computer, no censorship software, loss or disclosure of the password security ), without recourse against bobtvfr'
                        )}
                        ,
                    </p>
                    <p>
                        -{' '}
                        {t(
                            'to fully assume all the consequences of all kinds of declarations at all or partyally inaccurate, without recourse against its authors and bobtvfr'
                        )}
                        .
                    </p>
                </div>
                <div className="confirmation">
                    <div
                        className="confirmationLine"
                        onClick={() => {
                            if (!checkBox) setShowError(false)
                            setCheckBox(!checkBox)
                        }}
                    >
                        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                        <CheckboxInput checked={checkBox} onChange={() => {}} />
                        <p>
                            <Trans
                                i18nKey={
                                    'I confirm that I have read and accepted without reservation all the provisions contained in the Terms and Conditions of this server'
                                }
                            >
                                I confirm that I have read and accepted without reservation all the provisions contained
                                in the
                                <Link to={createLocalizedPath(paths.cgu.path)} target="_blank">
                                    {t('Terms and Conditions')}
                                </Link>
                                of this server
                            </Trans>
                        </p>
                    </div>
                    <p className="disclaimerError">
                        {showError && t('You must agree to the Terms and Conditions of Use for accessing this server')}
                    </p>
                    <div className="buttonLine">
                        <Button label={t('Enter')} onClick={handleEnterButton} />
                        <Button label={t('Exit')} onClick={() => window.location.replace('https://www.google.fr')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer
