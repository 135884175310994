import { configureStore, DeepPartial, Store } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
// import * as Sentry from '@sentry/react'
// import createSentryMiddleware from 'redux-sentry-middleware'
//@ts-ignore
import pmJsMiddleware from 'pm-js/middleware/pmMiddleware'

import rootReducer, { RootState } from './rootReducer'

const createStore = (preloadedState?: DeepPartial<RootState>): Store => {
    const store = configureStore({
        reducer: rootReducer,
        // @ts-ignore
        preloadedState,
        middleware: [
            thunk,
            pmJsMiddleware
            // @ts-ignore
            // createSentryMiddleware(Sentry, { breadcrumbDataFromAction: (Action) => ({ Action }) })
        ]
    })

    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./rootReducer', () => {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const newRootReducer = require('./rootReducer').default
            store.replaceReducer(newRootReducer)
        })
    }

    return store
}

export default createStore
