/* eslint i18next/no-literal-string: 0 */
import React from 'react'
import { useTranslation } from 'react-i18next'

const BobLinks = () => {
    const [t] = useTranslation()
    return (
        <div className="menuSection">
            <div className="menuSection__title">{t('Les sites de Bob')}</div>
            <div className="menuSection__items">
                <a href="https://www.bobtv.fr" target="_blank" rel="noreferrer" className="menuSection__item">
                    BobTV
                </a>
                <a href="https://www.bob4men.com/ru/" target="_blank" rel="noreferrer" className="menuSection__item">
                    Bob4men
                </a>
                <a href="https://bobstore.fr/" target="_blank" rel="noreferrer" className="menuSection__item">
                    Bobstore
                </a>
                <a href="http://www.youmebob.com/" target="_blank" rel="noreferrer" className="menuSection__item">
                    Youmebob
                </a>
            </div>
        </div>
    )
}

export default BobLinks
