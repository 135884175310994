import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../ui/button'

import { RootState } from '../../store/rootReducer'
import { createLocalizedPath } from '../../utils/localizationHelpers'
import { closePremiumDialog } from '../../store/common/commonSlice'
import './premiumDialod.sass'

const PremiumDialog: React.FunctionComponent = () => {
    const [t] = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const isPremiumDialogOpen = useSelector<RootState, boolean>((state) => state.common.isPremiumDialogOpen)

    return isPremiumDialogOpen ? (
        <div
            className="premiumWrapper"
            onClick={() => {
                dispatch(closePremiumDialog())
            }}
        >
            <div className="closeButton"></div>
            <div className="premiumRequireDialog" onClick={(event) => event.stopPropagation()}>
                <div className="premiumDialog">
                    <p className={'spanPremium'}>{t('Premium')}</p>
                    <p>{t('Membership')}</p>
                </div>
                <p className="informationText">{t('You need to become Premium member to use this feature')}</p>
                <div className={'buttonsGroup'}>
                    <Button
                        label={t('Become a Premium member')}
                        onClick={() => {
                            dispatch(closePremiumDialog())
                            history.push(createLocalizedPath('/membre/payment/'))
                        }}
                    />
                    <Button label={t('Cancel')} secondary={true} onClick={() => dispatch(closePremiumDialog())} />
                </div>
            </div>
        </div>
    ) : (
        <></>
    )
}

export default PremiumDialog
