import { getWrapper } from './axios'
import { formDataToRequestParams } from './helpers'
import {
    FetchProfile,
    FetchProfilePayload,
    FetchSuccess,
    FetchUserGiftsPayload,
    FollowResult,
    SendGuestbookMessage,
    SendGuestbookMessagePayload,
    UserId
} from '../store/profile/profileTypings'
import { DeleteGuestbookMessage, DeletePayload } from '../store/member/memberTypings'

export const getProfile = (params: FetchProfile): Promise<FetchProfilePayload> => {
    return getWrapper('/user/profile', { params }) as Promise<FetchProfilePayload>
}

export const sendGuestbookMessage = (params: SendGuestbookMessage): Promise<SendGuestbookMessagePayload> => {
    return getWrapper(
        '/user/guestbook/post/',
        formDataToRequestParams<SendGuestbookMessage>(params),
        true
    ) as Promise<SendGuestbookMessagePayload>
}

export const deleteGuestbookMessage = (params: DeleteGuestbookMessage): Promise<DeletePayload> => {
    return getWrapper(
        '/user/guestbook/delete/',
        formDataToRequestParams<DeleteGuestbookMessage>(params),
        true
    ) as Promise<DeletePayload>
}

export const isFollowingUser = (params: UserId): Promise<FollowResult> => {
    return getWrapper('/member/follower/check', { params }) as Promise<FollowResult>
}

export const isFolloweeUser = (params: UserId): Promise<FollowResult> => {
    return getWrapper('/member/followee/check', { params }) as Promise<FollowResult>
}

export const getUSerGifts = (params: { user_id: number; p?: number }): Promise<FetchUserGiftsPayload> => {
    return getWrapper('/user/gifts/', { params }) as Promise<FetchUserGiftsPayload>
}

export const setProfileVisited = (params: { user_id: number }): Promise<FetchSuccess> => {
    return getWrapper('/user/profile/hit/', { params }) as Promise<FetchSuccess>
}
