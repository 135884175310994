import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FilterButtonContext } from '../../provider/FilterButtonProvider/lib/FilterButtonContext'

import { RootState } from '../../store/rootReducer'
import { BackButtonType } from '../../store/common/commonSliceTyping'

import { deleteLastUrl, fixAppContainer, pageLoaded, switchMenu } from '../../store/common/commonSlice'
import { createLocalizedPath } from '../../utils/localizationHelpers'
import paths from '../../paths'
import Button from '../ui/button'
import last from 'lodash/last'

const MenuButtonContainer = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const isUserActive = useSelector<RootState, boolean>((state) => state.common.isUserActive)
    const isPageScrolled = useSelector<RootState, boolean>((state) => state.common.isPageScrolled)
    const backButton = useSelector<RootState, BackButtonType>((state) => state.common.backButton)
    const forceDisableMenu = useSelector<RootState, boolean>((state) => state.common.forceDisableMenu)

    const { setFilterButton, isNecessaryToShowFilters } = useContext(FilterButtonContext)

    const goBackButton = () => {
        if (backButton.urlList.length == 0) {
            history.push(createLocalizedPath(paths.index.path))
            return
        }
        if (last(backButton.urlList) == location.pathname) {
            if (backButton.urlList.length == 1 || !backButton.urlList[backButton.urlList.length - 2]) {
                history.push(createLocalizedPath(paths.index.path))
                return
            }
            history.push(backButton.urlList[backButton.urlList.length - 2])
            dispatch(deleteLastUrl())
        } else {
            history.push(last(backButton.urlList))
        }
        dispatch(deleteLastUrl())
    }

    return (
        <div
            className={
                isPageScrolled && !forceDisableMenu
                    ? isUserActive && !forceDisableMenu
                        ? 'headerButtonContainer scrolling'
                        : 'headerButtonContainer scrolling noActivity'
                    : 'headerButtonContainer'
            }
        >
            <div
                data-testid="switchButton"
                className="menuButton"
                onClick={() => {
                    dispatch(switchMenu())
                    dispatch(pageLoaded())
                    dispatch(fixAppContainer())
                }}
            />
            <div
                className={backButton.isNecessaryToShowBackButton ? 'backButton' : 'backButton hidden'}
                data-testid="back_button"
                onClick={goBackButton}
            >
                {backButton.avatarUrl && <img src={backButton.avatarUrl} alt=""></img>}
            </div>
            {isPageScrolled && isNecessaryToShowFilters && (
                <div onClick={(e) => e.stopPropagation()} className={'headerFilterButtonContainer'}>
                    <Button
                        label={''}
                        onClick={() => {
                            setFilterButton(true)
                        }}
                        secondary
                        filters
                        data-testid={'filterButton'}
                    />
                </div>
            )}
        </div>
    )
}

export default MenuButtonContainer
