export const NOTIFICATIONS_DESCRIPTION = {
    message: {
        icon: require('./../../../assets/ic-message.svg')
    },
    guestbook: {
        icon: require('./../../../assets/ic-message.svg')
    },
    gift: {
        icon: require('./../../../assets/ic-gift-blue.svg')
    },
    follower: {
        icon: require('./../../../assets/ic-followers.svg')
    },
    newContribution: {
        icon: require('./../../../assets/ic-new-contribution.svg')
    },
    premiumEnds: {
        icon: require('./../../../assets/ic-exclamation.svg')
    },
    premiumEnded: {
        icon: require('./../../../assets/ic-exclamation.svg')
    },
    premium: {
        icon: require('./../../../assets/ic-gift-blue.svg')
    },
    mapUser: {
        icon: require('./../../../assets/ic-map-marker-blue.svg'),
        text: 'New user near you',
        variant: 'No user'
    }
}
