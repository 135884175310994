import { getWrapper } from './axios'

import {
    FetchGeoList,
    FetchGeoPayload,
    UpdateGeoOptions,
    UpdateGeoPayload,
    UserLocationsTyping
} from '../store/geo/geoTypings'
import { formDataToRequestParams } from './helpers'

export const getGeoList = (params: FetchGeoList): Promise<FetchGeoPayload> => {
    return getWrapper('/geo/list/users', { params }) as Promise<FetchGeoPayload>
}

export const updateMyPosition = (params: UserLocationsTyping): Promise<UpdateGeoPayload> => {
    return getWrapper(
        '/geo/update/',
        formDataToRequestParams<UserLocationsTyping>(params),
        true
    ) as Promise<UpdateGeoPayload>
}

export const updateGeoOptions = (params: UpdateGeoOptions): Promise<{ status: string }> => {
    return getWrapper('/geo/options/', formDataToRequestParams<UpdateGeoOptions>(params), true) as Promise<{
        status: string
    }>
}

export const getGeoOption = (): Promise<UpdateGeoPayload> => {
    return getWrapper('/geo/options/get') as Promise<UpdateGeoPayload>
}
