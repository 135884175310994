import { getWrapper } from './axios'

import { formDataToRequestParams } from './helpers'

import {
    Login,
    FetchMemberPayload,
    Register,
    UploadPhoto,
    UploadPhotoPayload,
    EditPhotoType,
    EditPhotoPayload,
    DeletePhoto,
    SwitchGuestbook,
    DeletePayload,
    GetBlacklistedUsers,
    AddToBlacklist,
    AddToBlacklistPayload,
    RemoveFromBlacklist,
    UploadAvatar,
    UpdateProfile,
    GetBlacklistedUsersPayload,
    UploadAvatarPayload,
    GetFollowees,
    GetFolloweesPayload,
    FollowUser,
    FollowUserPayload,
    GetFollowersPayload,
    ChangeEmail,
    ValidateEmail,
    ChangePassword,
    ChangeNick,
    GetFavoriteContributorsPayload,
    GetContributions,
    GetContributionsPayload,
    ResetPassword,
    ValidateResetPassword,
    ChangeResetPassword,
    PaymentHistory,
    GetWalletHistoryPayload,
    FetchMemberGiftsPayload,
    FetchGuestbookPayload,
    FetchNotificationsListPayload,
    SetNotificationsSetting,
    BackendError,
    FetchUserForumPostsPayload,
    FetchUserForumPosts,
    AccountDelete,
    AccountDeletePayload
} from '../store/member/memberTypings'
import { FetchContributionsPayload } from '../store/contributions/contributionsTypings'
import { FetchMember } from '../store/profile/profileTypings'

export const getUserForumPosts = (params: FetchUserForumPosts) => {
    return getWrapper('/user/forum/message/list/', { params }) as Promise<FetchUserForumPostsPayload>
}

export const login = (params: Login): Promise<FetchMemberPayload> => {
    return getWrapper('/user/login/', formDataToRequestParams<Login>(params), true) as Promise<FetchMemberPayload>
}
export const notificationsSet = (notification_settings: SetNotificationsSetting): Promise<any> => {
    return getWrapper('/notification/setting/set/', notification_settings, true) as Promise<any>
}

export const getMemberNotifications = (params: { p: number }): Promise<FetchNotificationsListPayload> => {
    return getWrapper('/notification/list', { params }) as Promise<FetchNotificationsListPayload>
}

export const notification_read = (params: { ids: string }): Promise<{ status: string }> => {
    return getWrapper('/notification/read', { params }) as Promise<{ status: string }>
}

export const getMemberGifts = (params: { p: number }): Promise<FetchMemberGiftsPayload> => {
    return getWrapper('/member/gifts', { params }) as Promise<FetchMemberGiftsPayload>
}

export const getUserGuestbookList = (params: { p: number }): Promise<FetchGuestbookPayload> => {
    return getWrapper('/user/guestbook/list', { params }) as Promise<FetchGuestbookPayload>
}

export const getMemberSavedGifts = (params: { p: number }): Promise<FetchMemberGiftsPayload> => {
    return getWrapper('/member/gifts/outgoing', { params }) as Promise<FetchMemberGiftsPayload>
}

export const logout = (): Promise<FetchMemberPayload> => {
    return getWrapper('/user/logout', null, true) as Promise<FetchMemberPayload>
}

export const register = (params: Register): Promise<FetchMemberPayload> => {
    return getWrapper('/user/register/', formDataToRequestParams<Register>(params), true) as Promise<FetchMemberPayload>
}

export const deleteAccount = (params: AccountDelete): Promise<AccountDeletePayload> => {
    return getWrapper(
        '/member/account/delete',
        formDataToRequestParams<AccountDelete>(params),
        true
    ) as Promise<AccountDeletePayload>
}

export const getMember = (params: FetchMember): Promise<FetchMemberPayload> => {
    return getWrapper('/member', { params }) as Promise<FetchMemberPayload>
}

export const requestSMS = (params: { phone: string }): Promise<{ status: string; phone: string; message: string }> => {
    return getWrapper(
        '/user/register/request/sms',
        formDataToRequestParams<{ phone: string }>(params),
        true
    ) as Promise<{ status: string; phone: string; message: string }>
}

export const activate = (params: { code: string }): Promise<FetchMemberPayload> => {
    return getWrapper(
        '/user/activate/code',
        formDataToRequestParams<{ code: string }>(params),
        true
    ) as Promise<FetchMemberPayload>
}

export const requestChangeEmail = (params: {
    email: string
}): Promise<{ status: string; email: string; message: string }> => {
    return getWrapper(
        '/user/register/change/email',
        formDataToRequestParams<{ email: string }>(params),
        true
    ) as Promise<{ status: string; email: string; message: string }>
}

export const uploadPhoto = (params: UploadPhoto): Promise<UploadPhotoPayload> => {
    return getWrapper(
        '/member/upload/photo/',
        formDataToRequestParams<UploadPhoto>(params),
        true
    ) as Promise<UploadPhotoPayload>
}

export const editPhoto = (params: EditPhotoType): Promise<EditPhotoPayload> => {
    return getWrapper(
        '/member/photo/update/',
        formDataToRequestParams<EditPhotoType>(params),
        true
    ) as Promise<EditPhotoPayload>
}

export const deletePhoto = (params: DeletePhoto): Promise<DeletePayload> => {
    return getWrapper(
        '/member/photo/delete/',
        formDataToRequestParams<DeletePhoto>(params),
        true
    ) as Promise<DeletePayload>
}

export const switchGuestbook = (params: SwitchGuestbook): Promise<FetchMemberPayload> => {
    return getWrapper(
        '/member/guestbook/disable/',
        formDataToRequestParams<SwitchGuestbook>(params),
        true
    ) as Promise<FetchMemberPayload>
}

// export const deleteGuestbookMessage = (params: DeleteGuestbookMessage): Promise<DeletePayload> => {
//     return getWrapper(
//         '/member/guestbook/delete',
//         formDataToRequestParams<DeleteGuestbookMessage>(params),
//         true
//     ) as Promise<DeletePayload>
// }

export const getBlacklistedUsers = (params: GetBlacklistedUsers): Promise<GetBlacklistedUsersPayload> => {
    return getWrapper('/member/blacklist/list', { params }) as Promise<GetBlacklistedUsersPayload>
}

export const addToBlacklist = (params: AddToBlacklist): Promise<AddToBlacklistPayload> => {
    return getWrapper(
        '/member/blacklist/add/',
        formDataToRequestParams<AddToBlacklist>(params),
        true
    ) as Promise<AddToBlacklistPayload>
}

export const removeFromBlacklist = (params: RemoveFromBlacklist): Promise<DeletePayload> => {
    return getWrapper(
        '/member/blacklist/delete/',
        formDataToRequestParams<RemoveFromBlacklist>(params),
        true
    ) as Promise<DeletePayload>
}

export const uploadAvatar = (params: UploadAvatar): Promise<UploadAvatarPayload> => {
    return getWrapper(
        '/member/upload/avatar/',
        formDataToRequestParams<UploadAvatar>(params),
        true
    ) as Promise<UploadAvatarPayload>
}

export const updateProfile = (params: UpdateProfile): Promise<FetchMemberPayload> => {
    return getWrapper(
        '/member/update/',
        formDataToRequestParams<UpdateProfile>(params),
        true
    ) as Promise<FetchMemberPayload>
}

export const getFollowees = (params: GetFollowees): Promise<GetFolloweesPayload> => {
    return getWrapper('/member/followee/list', { params }) as Promise<GetFolloweesPayload>
}

export const getWalletHistory = (params: { p: number }): Promise<GetWalletHistoryPayload> => {
    return getWrapper('/member/wallet/history', { params }) as Promise<GetWalletHistoryPayload>
}

export const getFollowers = (params: GetFollowees): Promise<GetFollowersPayload> => {
    return getWrapper('/member/follower/list', { params }) as Promise<GetFollowersPayload>
}

export const getContributions = (params: GetContributions): Promise<GetContributionsPayload> => {
    return getWrapper('/user/contribution/list', { params }) as Promise<GetContributionsPayload>
}

export const getMemberContributions = (params: GetContributions): Promise<GetContributionsPayload> => {
    return getWrapper('/user/contribution/list', { params }) as Promise<GetContributionsPayload>
}

export const getFavoriteContributors = (params: GetFollowees): Promise<GetFavoriteContributorsPayload> => {
    return getWrapper('/member/followee/list', { params }) as Promise<GetFavoriteContributorsPayload>
}

export const addFollowedUser = (params: FollowUser): Promise<FollowUserPayload> => {
    return getWrapper(
        '/member/followee/add/',
        formDataToRequestParams<FollowUser>(params),
        true
    ) as Promise<FollowUserPayload>
}

export const removeFollowedUser = (params: FollowUser): Promise<DeletePayload> => {
    return getWrapper(
        '/member/followee/delete/',
        formDataToRequestParams<FollowUser>(params),
        true
    ) as Promise<DeletePayload>
}

export const removeFavoriteContributors = (params: FollowUser): Promise<DeletePayload> => {
    return getWrapper(
        '/member/followee/delete/',
        formDataToRequestParams<FollowUser>(params),
        true
    ) as Promise<DeletePayload>
}

export const changeEmail = (params: ChangeEmail): Promise<{ message: string }> => {
    return getWrapper('/member/change/email/request/', formDataToRequestParams<ChangeEmail>(params), true) as Promise<{
        message: string
    }>
}

export const validateEmail = (params: ValidateEmail): Promise<{ message: string }> => {
    return getWrapper(
        '/member/change/email/validate/',
        formDataToRequestParams<ValidateEmail>(params),
        true
    ) as Promise<{ message: string }>
}

export const changePassword = (params: ChangePassword): Promise<{ message: string }> => {
    return getWrapper('/member/change/password/', formDataToRequestParams<ChangePassword>(params), true) as Promise<{
        message: string
    }>
}

export const changeNick = (params: ChangeNick): Promise<{ message: string }> => {
    return getWrapper('/member/change/nick/', formDataToRequestParams<ChangeNick>(params), true) as Promise<{
        message: string
    }>
}

export const resetPassword = (params: ResetPassword): Promise<{ message: string }> => {
    return getWrapper('/member/password/reset/', formDataToRequestParams<ResetPassword>(params), true) as Promise<{
        message: string
    }>
}

export const validateResetPassword = (params: ValidateResetPassword): Promise<{ message: string }> => {
    return getWrapper(
        '/member/password/reset/validate/',
        formDataToRequestParams<ValidateResetPassword>(params),
        true
    ) as Promise<{ message: string }>
}

export const changeResetPassword = (params: ChangeResetPassword): Promise<{ message: string }> => {
    return getWrapper(
        '/member/password/reset/change/',
        formDataToRequestParams<ChangeResetPassword>(params),
        true
    ) as Promise<{ message: string }>
}

export const getMemberFavoriteContributions = (params: { p: number }): Promise<FetchContributionsPayload> => {
    return getWrapper(
        '/member/favourites/list/contributions/',
        formDataToRequestParams<{ p: number }>(params),
        true
    ) as Promise<FetchContributionsPayload>
}

export const getPaymentHistory = (params: { p: number }): Promise<PaymentHistory> => {
    return getWrapper(
        '/member/payment/history/',
        formDataToRequestParams<{ p: number }>(params),
        true
    ) as Promise<PaymentHistory>
}

export const postUserActivateToken = (params: { token: string }): Promise<BackendError> => {
    return getWrapper(
        '/user/activate/token/',
        formDataToRequestParams<{ token: string }>(params),
        true
    ) as Promise<BackendError>
}
