let passiveSupported = false
try {
    window.addEventListener(
        'test',
        null,
        Object.defineProperty({}, 'passive', {
            get: function () {
                passiveSupported = true
            }
        })
    )
} catch (err) {
    console.log('Your browser does not support passive event handlers')
}
export default passiveSupported
