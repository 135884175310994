import * as React from 'react'
import { useTranslation } from 'react-i18next'
import './popup.sass'

interface Props {
    title?: string
    text?: string
    classname?: string
    onClose: () => void
    onKeyDown?: (e: React.KeyboardEvent) => void
    noCloseButton?: boolean
    children?: React.ReactNode
    buttons?: React.ReactNode
    profile?: boolean
}

const Index: React.FunctionComponent<Props> = ({
    onClose,
    children,
    title,
    text,
    classname,
    buttons,
    noCloseButton = false,
    profile = false
}: Props) => {
    const [t] = useTranslation()
    return (
        <div className={classname ? `popup ${classname}` : 'popup'}>
            <div className="popup__overlay" onClick={onClose} />
            {!noCloseButton && (
                <div className="buttonContainer">
                    <div className="closeButton" data-testid="close_button" onClick={onClose} />
                </div>
            )}
            <div className={profile ? 'popup__content shorProfilePopup' : 'popup__content'}>
                {title && <div className="popup__title">{t(title)}</div>}
                {text && <div className="popup__text">{t(text)}</div>}
                {children}
                {buttons && <div className="popup__buttons">{buttons}</div>}
            </div>
        </div>
    )
}

export default Index
