import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getContributions, getFollowers, getUserForumPosts, getUserGuestbookList } from '../../api/member'
import { deleteGuestbookMessage, getProfile, getUSerGifts, sendGuestbookMessage } from '../../api/profile'
import {
    ProfileState,
    FetchProfile,
    SendGuestbookMessage,
    FetchProfilePayload,
    SendGuestbookMessagePayload,
    FetchUserGiftsPayload
} from './profileTypings'

import { profileFulfilledReducer } from '../commonReducers'
import {
    DeleteGuestbookMessage,
    DeletePayload,
    FetchGuestbookPayload,
    FetchUserForumPosts,
    FetchUserForumPostsPayload,
    GetContributions,
    GetContributionsPayload,
    GetFollowees,
    GetFollowersPayload
} from '../member/memberTypings'
import { GuestbookMessage } from '../commonTypes'

const initialState: ProfileState = {
    user: null,
    followers: [],
    followersTotal: null,
    contributionsList: [],
    contributionsTotal: null,
    loading: false,
    userPhotos: [],
    userGuestbook: [],
    guestbookTotal: null,
    userContributions: [],
    userGifts: [],
    userGiftsTotal: null,
    profileLoaded: false,
    userForumPosts: [],
    userForumPostsTotal: null
}

export const fetchUserForumPosts = createAsyncThunk<FetchUserForumPostsPayload, FetchUserForumPosts>(
    'fetchUserForumPosts',
    (params) => getUserForumPosts(params)
)

export const fetchProfile = createAsyncThunk<FetchProfilePayload, FetchProfile>('profile/fetch', (params) =>
    getProfile(params)
)

export const fetchContributionsInProfile = createAsyncThunk<GetContributionsPayload, GetContributions>(
    'fetchContributionsInProfile',
    (params: GetContributions) => getContributions(params)
)

export const postGuestbookMessage = createAsyncThunk<SendGuestbookMessagePayload, SendGuestbookMessage>(
    'guestbook/send',
    (params) => sendGuestbookMessage(params)
)

export const fetchProfileFollowers = createAsyncThunk<GetFollowersPayload, GetFollowees>(
    'profile/fetchFollowers',
    (params) => getFollowers(params)
)

export const fetchProfileGifts = createAsyncThunk<FetchUserGiftsPayload, { user_id: number; p?: number }>(
    'profile/gifts',
    (params) => getUSerGifts(params)
)

export const userGuestbookMessageDelete = createAsyncThunk<DeletePayload, DeleteGuestbookMessage>(
    'deleteGuestbookMessage',
    (params) => deleteGuestbookMessage(params)
)

export const fetchUserGuestbookList = createAsyncThunk<FetchGuestbookPayload, { nick: string; p: number }>(
    'fetchMemberGuestbookLIst',
    (params) => getUserGuestbookList(params)
)

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfile.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchProfile.rejected, (state) => {
                state.loading = false
            })
            .addCase(fetchProfileGifts.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchProfileGifts.rejected, (state) => {
                state.loading = false
            })
            .addCase(fetchProfileGifts.fulfilled, (state, action) => {
                state.userGifts = action.payload.list
                state.userGiftsTotal = action.payload.total_count
            })
            .addCase(fetchProfile.fulfilled, profileFulfilledReducer)

            .addCase(postGuestbookMessage.fulfilled, (state, action) => {
                state.userGuestbook = [action.payload.guestbookMessage, ...state.userGuestbook]
            })

            .addCase(fetchProfileFollowers.fulfilled, (state, action) => {
                state.followers = action.payload.list
                state.followersTotal = Number(action.payload.total)
            })
            .addCase(fetchContributionsInProfile.fulfilled, (state, action) => {
                state.contributionsList = action.payload.list
                state.contributionsTotal = Number(action.payload.total_count)
            })
            .addCase(fetchUserGuestbookList.fulfilled, (state, action) => {
                state.userGuestbook = action.payload.list
                state.guestbookTotal = Number(action.payload.total)
            })
            .addCase(userGuestbookMessageDelete.fulfilled, (state, action) => {
                state.userGuestbook = state.userGuestbook.filter(
                    (item: GuestbookMessage) => item.id !== action.meta.arg.id
                )
            })
            .addCase(fetchUserForumPosts.fulfilled, (state, action) => {
                state.userForumPosts = action.payload.list
                state.userForumPostsTotal = Number(action.payload.total_count)
            })
    }
})

export default profileSlice.reducer
