// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notFound-module__notFoundText___GMnIc {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n     -moz-box-orient: vertical;\n     -moz-box-direction: normal;\n          flex-direction: column;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  font-size: 24px;\n  text-align: center;\n  padding: 10px;\n}\n.notFound-module__notFoundText___GMnIc p:first-of-type {\n  font-size: 32px;\n  font-weight: 700;\n}", "",{"version":3,"sources":["webpack://./notFound.module.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA,aAAA;EACA,4BAAA;EAAA,6BAAA;EAAA,8BAAA;KAAA,yBAAA;KAAA,0BAAA;UAAA,sBAAA;EACA,wBAAA;EAAA,+BAAA;KAAA,qBAAA;UAAA,uBAAA;EACA,yBAAA;EAAA,2BAAA;KAAA,sBAAA;UAAA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".notFoundText\r\n  position: fixed\r\n  left: 0\r\n  top: 0\r\n  width: 100vw\r\n  height: 100vh\r\n  display: flex\r\n  flex-direction: column\r\n  justify-content: center\r\n  align-items: center\r\n  font-size: 24px\r\n  text-align: center\r\n  padding: 10px\r\n  p:first-of-type\r\n    font-size: 32px\r\n    font-weight: 700"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFoundText": "notFound-module__notFoundText___GMnIc"
};
export default ___CSS_LOADER_EXPORT___;
