import { PayloadAction, AnyAction } from '@reduxjs/toolkit'

import { FetchProfilePayload, ProfileState } from './profile/profileTypings'
import { MemberState } from './member/memberTypings'
import { ContributionsState } from './contributions/contributionsTypings'
import { currentErrorInfo } from '../api/axios'

export const profileFulfilledReducer = (state: ProfileState, action: PayloadAction<FetchProfilePayload>): void => {
    state.loading = false
    state.profileLoaded = true
    state.user = {
        ...state.user,
        ...action.payload.user
    }
    state.userPhotos = action.payload.userPhotos
    state.userGuestbook = action.payload.userGuestbook
    state.userContributions = action.payload.userContributions
    if (action.payload.userForumPosts) state.userForumPosts = action.payload.userForumPosts
}

export const pendingMemberReducer = (state: MemberState): void => {
    state.error = null
    state.loading = true
}

export const rejectedMemberReducer = (state: MemberState, action: AnyAction): void => {
    state.loading = false
    state.triedLoad = true
    state.error = action.error.message
}

export const rejectRegisterReducer = (state: MemberState): void => {
    state.loading = false
    state.triedLoad = true
    let message = ''
    // @ts-ignore
    if (currentErrorInfo.message.nick) message += currentErrorInfo.message.nick
    // @ts-ignore
    if (currentErrorInfo.message.email) message += '\n' + currentErrorInfo.message.email
    state.error = message
}

export const pendingContributionsReducer = (state: ContributionsState): void => {
    state.error = null
    state.loading = true
}

export const rejectedContributionsReducer = (state: ContributionsState, action: AnyAction): void => {
    state.loading = false
    state.error = action.error.message
}
